import { Coordinates } from '@/types';
import { createQueryKeys, mergeQueryKeys } from '@lukemorales/query-key-factory';
import { GetDeliveryDatesParams } from './delivery';

export const baskets = createQueryKeys('baskets', {
  all: null,
  byId: (basketId: Ref<string | null | undefined> | string) => ({
    queryKey: [basketId],
    contextQueries: {
      validation: null,
    },
  }),
});

type AddressRefParams = Ref<{
  address1: string | null;
  address2: string | null;
  city: string | null;
  zip: string | null;
  phone: string | null;
  lat: string | null;
  lon: string | null;
} | null>;

type DeliveryEstimationParams = {
  restaurantId: Ref<string | null>;
  timeSlot: Ref<'asap' | string | null>;
  address: AddressRefParams;
  numberOfItems?: Ref<number>;
};

export const customer = createQueryKeys('customer', { details: null, storeCreditHistory: null });

export const takeaway = createQueryKeys('takeaway', {
  eta: ({ address, restaurantId, timeSlot, numberOfItems }: DeliveryEstimationParams) => [
    address,
    timeSlot,
    restaurantId,
    numberOfItems,
  ],
  restaurant: (restaurantId: Ref<string | number | null> | string) => ({
    queryKey: [restaurantId],
    contextQueries: {
      menu: (fulfillmentType?: Ref<'delivery' | 'pickup' | 'eat_in' | null>) => [
        restaurantId,
        fulfillmentType,
      ],
      snoozedProducts: (fulfillmentType?: Ref<'delivery' | 'pickup' | 'eat_in' | null>) => [
        restaurantId,
        fulfillmentType,
      ],
      timeslots: null,
    },
  }),
  driver: (orderId: Ref<string | null> | string) => [orderId],
  nearestStores: (coordinates: Ref<Coordinates | null>) => [coordinates],
});

export const orders = createQueryKeys('orders', {
  byId: (orderId: Ref<string | null> | string) => [orderId],
  preview: (
    items:
      | Ref<
          {
            quantity: number;
            productVariantId: string;
          }[]
        >
      | string,
    scheduleWeekId: Ref<string | undefined> | string,
    voucherCode: Ref<string | undefined> | string
  ) => [items, scheduleWeekId, voucherCode],
  live: null,
  scheduleWeeks: null,
  upcoming: null,
  past: null,
});

export const deliveryFees = createQueryKeys('deliveryFees', {
  all: null,
});
export const productReviews = createQueryKeys('productReviews', {
  byMenus: (menus: Ref<string[]>) => [menus],
});

export const deliveryDates = createQueryKeys('deliveryDates', {
  byParams: (params: Ref<GetDeliveryDatesParams>) => [params],
});

export type UseGetDeliveryInstructionsParams = {
  deliveryDate: string;
  postcode: string;
};

export const deliveryInstriuctions = createQueryKeys('deliveryInstriuctions', {
  byParams: (params: Ref<UseGetDeliveryInstructionsParams | undefined> | undefined) => [params],
});

export const marketing = createQueryKeys('marketing', {
  blocks: null,
  offers: null,
});

export const subscriptions = createQueryKeys('subscriptions', {
  all: null,
});

export const payments = createQueryKeys('payments', {
  all: null,
});

export const addresses = createQueryKeys('addresses', {
  all: null,
});

export const vouchers = createQueryKeys('vouchers', {
  byId: (params: Ref<string | null | undefined> | string) => [params],
});

export const settings = createQueryKeys('settings', {
  all: null,
});

export const queries = mergeQueryKeys(
  customer,
  baskets,
  takeaway,
  orders,
  deliveryFees,
  productReviews,
  deliveryDates,
  deliveryInstriuctions,
  marketing,
  subscriptions,
  payments,
  addresses,
  vouchers,
  settings
);
