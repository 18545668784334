import { axios } from '@/utils/index';
import {
  PromiseResponse,
  Basket,
  Baskets,
  EstimatedDeliveryTime,
  PaymentServerResponse,
  ObjectToCamel,
  BasketValidationError,
  Address,
  NormalizedBasket,
} from '@pasta-evangelists/pasta-types';
import normalize from '@/utils/normalize';
import { decamelizeKeys } from 'humps';
import { isAxiosError } from 'axios';

export interface TakeawaySubItems {
  productVariantId: string;
  parentProductId: string;
  quantity: number;
  subItems: TakeawaySubItems[];
}

interface TakeawayItemParams {
  basketId: string;
  productVariantId: string;
  quantity: number;
  subItems: TakeawaySubItems[];
}

export const addTakeawayItem = async (
  itemParams: TakeawayItemParams
): PromiseResponse<NormalizedBasket> => {
  const { basketId, ...params } = itemParams;
  try {
    const { data } = await axios.post<Basket>(`baskets/${basketId}/items`, decamelizeKeys(params));
    return normalize<NormalizedBasket>(data);
  } catch (e) {
    throw new Error('Something went wrong!');
  }
};

interface CreateBasketParams {
  type: 'SubscriptionBasket' | 'OneOffBasket' | 'TakeawayBasket';
  deliveryDate?: string;
  restaurantId?: string | number;
  timeSlot?: string;
  fulfillmentType?: string;
  address: Omit<Address, 'id' | 'isDefault'>;
}

type UpdateBasketParams = Partial<Omit<CreateBasketParams, 'type' | 'address'>> & {
  id: string;
  validateFully?: boolean;
  address?: Partial<Omit<Address, 'id' | 'isDefault'>>;
  tableNumber?: string | null;
  customerEmail?: string | null;
};

export const updateBasket = async (
  params: UpdateBasketParams
): PromiseResponse<NormalizedBasket> => {
  const { id, ...rest } = params;
  const { data } = await axios.put<Baskets>(`baskets/${id}`, decamelizeKeys(rest));
  return normalize(data);
};

interface ValidateBasketParams {
  basketId: string;
  validateFully?: boolean;
}

export interface ApiResponseSuccess<T> {
  data: ObjectToCamel<T>;
  error: null;
}

export interface InvalidBasketApiResponseError {
  data: null;
  error: {
    message: string;
    code: string;
    errors: Record<string, string[]> | null;
  };
}

export declare type ValidateBasketResponse<T> = Promise<
  ApiResponseSuccess<T> | InvalidBasketApiResponseError
>;

export const validateBasket = async ({
  basketId,
  validateFully = false,
}: ValidateBasketParams): ValidateBasketResponse<true> => {
  try {
    await axios.get<true, BasketValidationError>(
      `baskets/${basketId}/validate?validate_fully=${validateFully}`
    );
    return { data: true, error: null };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    if (isAxiosError(e) && e.response?.status === 400 && e.response?.data) {
      if (typeof e.response.data?.error === 'object') {
        return { data: null, ...e.response?.data };
      }
      return {
        data: null,
        error: { message: e.response.data.message, code: e.response.data.error, errors: null },
      };
    }
    return {
      data: null,
      error: { message: "Couldn't validate basket", code: '500', errors: null },
    };
  }
};

export type DeliveryEstimationParams = {
  restaurantId: string;
  timeSlot: 'asap' | string;
  address1: string;
  address2: string;
  city: string;
  zip: string;
  phone: string;
  lat: string;
  lon: string;
  country: string;
  numberOfItems?: number;
};

export const getDeliveryEstimation = async (
  params: DeliveryEstimationParams
): PromiseResponse<EstimatedDeliveryTime> => {
  try {
    const { data } = await axios.post<EstimatedDeliveryTime>(
      `delivery_estimation`,
      decamelizeKeys(params)
    );
    return data;
  } catch (e) {
    throw new Error("Couldn't get delivery time estimation");
  }
};

interface createTakeawayOrderParams {
  basketId: string;
  note: string;
  kitchenNote: string;
  paymentMethodId?: string;
}

interface ServerCreateOrderResponse {
  payment_intent_client_secret: string | null;
  order_id: string;
  payment: PaymentServerResponse;
}

export type CreateTakeawayOrderResponse = ObjectToCamel<ServerCreateOrderResponse>;

export const createOrder = async (
  params: createTakeawayOrderParams
): ValidateBasketResponse<CreateTakeawayOrderResponse> => {
  try {
    const { data } = await axios.post<ObjectToCamel<ServerCreateOrderResponse>>(
      `orders/takeaway`,
      decamelizeKeys(params)
    );
    return { data, error: null };
  } catch (e) {
    if (isAxiosError(e) && e.response?.status === 400 && e.response?.data) {
      return { data: null, ...e.response?.data };
    }
    return {
      data: null,
      error: { message: 'Failed while trying to create order', code: '500', errors: null },
    };
  }
};

interface createGuestOrderParams {
  basketId: string;
  paymentMethodType: string;
  paymentProcessorName: string;
  kitchenNote?: string;
}

export const createGuestOrder = async (
  params: createGuestOrderParams
): ValidateBasketResponse<CreateTakeawayOrderResponse> => {
  try {
    const { data } = await axios.post<ObjectToCamel<ServerCreateOrderResponse>>(
      `orders/guest`,
      decamelizeKeys(params)
    );
    return { data, error: null };
  } catch (e) {
    if (isAxiosError(e) && e.response?.status === 400 && e.response?.data) {
      return { data: null, ...e.response?.data };
    }
    return {
      data: null,
      error: { message: 'Failed while trying to create order', code: '500', errors: null },
    };
  }
};

interface RemoveItemsFromBasketParams {
  basketId: string;
  ids: string[];
}

export const removeItemsFromBasket = async (
  params: RemoveItemsFromBasketParams
): Promise<NormalizedBasket> => {
  const result = await axios.delete<Basket>(`baskets/${params.basketId}/items/many`, {
    data: { ids: params.ids },
  });
  return normalize(result.data);
};
