import currentCustomerStore from '@/stores/currentCustomerStore';
import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router';
import * as AuthApi from '@/api/auth';
import { trackCustomEvent } from './analytics';
import { convertCheckoutTypeToMenuType, getAllUserInfo } from '.';
import usePastaNowBasketStore from '@/stores/pastaNowBasketStore';
import useBasketStore from '@/stores/basketStore';

const isLoggedIn = async (): Promise<boolean> => {
  const currentUserStore = currentCustomerStore();
  await currentUserStore.getUserDetails();

  if (currentUserStore.email) {
    getAllUserInfo();
  }

  return Boolean(currentUserStore.email);
};

export const authRouteGuard = async (to: RouteLocationNormalized, next: NavigationGuardNext) => {
  let logged = false;

  if (to.query.token && typeof to.query.token === 'string') {
    await AuthApi.logout();
    await AuthApi.loginWithToken(to.query.token);
  }

  logged = await isLoggedIn();

  const nonLoggedRoutes = ['login', 'signup', 'password-recover', 'social-login'];
  if (to.name && typeof to.name === 'string' && nonLoggedRoutes.includes(to.name)) {
    logged ? next('/') : next();
  } else {
    // check to see if there is a token for login

    logged ? next() : next({ path: '/social-login', query: { returnTo: to.fullPath } });
  }
};

export const myBasketRouteGuard = async (
  to: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  let logged = false;

  if (to.query.token && typeof to.query.token === 'string') {
    await AuthApi.logout();
    await AuthApi.loginWithToken(to.query.token);
  }

  const isRK = to.name === 'my-rk-basket';

  logged = await isLoggedIn();
  logged
    ? isRK
      ? next({ name: 'product-box', query: { vc: to.query?.vc } })
      : next({ name: 'pasta-now', query: { vc: to.query?.vc } })
    : next({ name: 'pasta-now-search-address', query: { vc: to.query?.vc } });
};

type CheckoutType = 'one-off' | 'subscription' | 'pasta-now';

const goToStartOfCheckoutOrContinue = (
  to: RouteLocationNormalized,
  next: NavigationGuardNext,
  checkoutType: CheckoutType = 'subscription'
) => {
  const pastaNowBasket = usePastaNowBasketStore();
  const customerStore = currentCustomerStore();
  const basketStore = useBasketStore();

  const redirect = {
    instructions: {
      subscription: 'delivery-instructions',
      'one-off': 'one-off-delivery-instructions',
      'pasta-now': 'pasta-now-delivery-instructions',
    },
    address: {
      subscription: 'delivery-address',
      'one-off': 'one-off-delivery-address',
      'pasta-now': 'pasta-now-delivery-address',
    },
  };

  if (
    to.path !== '/checkout' &&
    to.path !== '/one-off/checkout' &&
    to.path !== '/pasta-now-checkout'
  ) {
    next();
    return;
  }

  if (checkoutType === 'pasta-now' && !pastaNowBasket.isDelivery)
    next({ name: redirect.instructions[checkoutType], query: { ...to.query } });

  // when trying to access the checkout page,
  // check if we have the delivery address to
  // continue to the instructions screen
  const hasAddress =
    (checkoutType === 'pasta-now' && pastaNowBasket.address?.address1) ||
    (checkoutType !== 'pasta-now' && basketStore.address?.address1);
  if (hasAddress && customerStore.firstName && customerStore.lastName) {
    next({ name: redirect.instructions[checkoutType], query: { ...to.query } });
  } else {
    next({ name: redirect.address[checkoutType], query: { ...to.query } });
  }
};

export const checkoutAuthRouteGuard = async (
  to: RouteLocationNormalized,
  next: NavigationGuardNext,
  checkoutType: CheckoutType = 'subscription'
) => {
  const logged = await isLoggedIn();
  const authNames = [
    'checkout-login',
    'checkout-signup',
    'checkout-social-login',
    'pasta-now-checkout-login',
    'pasta-now-checkout-signup',
    'pasta-now-checkout-social-login',
  ];
  // remove duplicated events
  if (logged && to?.path.split('/').length > (checkoutType === 'one-off' ? 3 : 2))
    trackCustomEvent('login_step_in_checkout', {
      login_attempted: 'Bypassed',
      method: 'Bypassed',
      menu_type: convertCheckoutTypeToMenuType(checkoutType),
    });

  if (checkoutType === 'one-off') {
    logged
      ? goToStartOfCheckoutOrContinue(to, next, checkoutType)
      : next({ name: 'social-login', query: { returnTo: 'one-off/menu' } });
    return;
  }

  if (to.name && typeof to.name === 'string' && authNames.includes(to.name)) {
    logged ? goToStartOfCheckoutOrContinue(to, next, checkoutType) : next();
  } else {
    const loginPageName =
      checkoutType === 'subscription' ? 'checkout-social-login' : 'pasta-now-checkout-social-login';
    logged ? goToStartOfCheckoutOrContinue(to, next, checkoutType) : next({ name: loginPageName });
  }
};

export const giftAuthRouteGuard = async (
  to: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  const logged = await isLoggedIn();
  if (logged)
    trackCustomEvent('login_step_in_checkout', {
      login_attempted: 'Bypassed',
      method: undefined,
      menu_type: 'One-off',
      fulfilment_type: 'delivery',
    });
  logged ? next({ name: 'one-off-delivery-instructions', query: to.query }) : next();
};

export const pastaNowRouteGuard = async (
  to: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  const logged = await isLoggedIn();
  if (to.name === 'pasta-now-restaurant-list') {
    logged ? next() : next('/pasta-now/authenticate');
  } else {
    logged ? next('/pasta-now/restaurant-list') : next();
  }
};

export const orderConfirmationGuard = async (
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  const logged = await isLoggedIn();
  if (
    from.name !== 'checkout-payment' &&
    from.name !== 'one-off-checkout-payment' &&
    from.name !== 'paypal-confirmation' &&
    from.name !== 'paypal-setup-confirmation'
  ) {
    logged ? next('/account') : next('/');
  } else {
    next();
  }
};

export const pastaNowNameGuard = async (next: NavigationGuardNext) => {
  await isLoggedIn();

  const customerStore = currentCustomerStore();
  const pastaNowBasket = usePastaNowBasketStore();

  const hasFirstName = Boolean(pastaNowBasket.customerName?.firstName || customerStore.firstName);
  const hasLastName = Boolean(pastaNowBasket.customerName?.lastName || customerStore.lastName);

  if (!pastaNowBasket.isDelivery) return next();

  if (pastaNowBasket.fulfillmentType === 'eat_in') return next({ name: 'pasta-now-payment' });

  if (!hasFirstName || !hasLastName || pastaNowBasket.address?.address1 === 'ignore_me')
    next({ name: 'pasta-now-delivery-address', params: { isOpen: 'true' } });
  else next();
};
