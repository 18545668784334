import {
  CustomerData,
  NormalizedOrders,
  NormalizedOrderWithAddressIncluded,
  NormalizedScheduledOrders,
  Order,
  OrderServerResponse,
  PaginationMeta,
  ProductRating,
  RatingResponse,
  ScheduledOrder,
  ScheduledOrdersServerResponse,
  SingleOrderServerResponse,
  SingleOrderWithAddressServerResponse,
  SingleOrderWithFullInfoServerResponse,
  OrderFeedbackServerResponse,
  PaymentServerResponse,
  PromiseResponse,
} from '@pasta-evangelists/pasta-types';
import { axios } from '@/utils';
import normalize from '@/utils/normalize';
import { ApiResponse, ObjectToCamel } from '@pasta-evangelists/pasta-types';
import { decamelizeKeys } from 'humps';
import * as Sentry from '@sentry/vue';
import { isAxiosError } from 'axios';

export const upcomingOrders = async (): PromiseResponse<NormalizedOrderWithAddressIncluded> => {
  try {
    const { data } = await axios.get<OrderServerResponse>(
      'orders?filter[delivery_date]=upcoming&filter[type]=ShopifyOrder&per_page=3000'
    );
    return normalize<NormalizedOrderWithAddressIncluded>(data);
  } catch (e) {
    throw new Error("Couldn't retrieve upcoming orders");
  }
};

interface GetPastOrdersParams {
  page: number;
  perPage?: number;
}

export const getPastOrders = async ({
  page,
  perPage = 20,
}: GetPastOrdersParams): PromiseResponse<{
  normalizedOrders: NormalizedOrderWithAddressIncluded;
  meta: ObjectToCamel<PaginationMeta>;
}> => {
  try {
    const { data } = await axios.get<ObjectToCamel<OrderServerResponse>>(
      `orders?filter[delivery_date]=past&filter[exclude_states]=dropped&page=${page}&per_page=${perPage}`
    );

    return {
      normalizedOrders: normalize<NormalizedOrderWithAddressIncluded>(data),
      meta: data.meta,
    };
  } catch (e) {
    throw new Error('We were unable to process your request');
  }
};

export const getOneRandomPastOrder = async (): ApiResponse<NormalizedOrderWithAddressIncluded> => {
  try {
    const { data } = await axios.get<ObjectToCamel<OrderServerResponse>>(
      `orders?filter[delivery_date]=past&filter[type]=ShopifyOrder&page=1&per_page=1`
    );

    return {
      data: normalize<NormalizedOrderWithAddressIncluded>(data),
      error: null,
    };
  } catch (e) {
    return { data: null, error: { message: 'We were unable to process your request' } };
  }
};

export const getMostRecentOrderForSubscription = async (
  subscriptionId: string
): ApiResponse<NormalizedOrderWithAddressIncluded | null> => {
  try {
    const { data } = await axios.get<ObjectToCamel<OrderServerResponse>>(
      `orders?filter[subscription_id]=${subscriptionId}&filter[delivery_date]=past&filter[type]=ShopifyOrder&page=1&per_page=1`
    );

    if (data.data.length === 0) {
      return { data: null, error: null };
    }

    return {
      data: normalize<NormalizedOrderWithAddressIncluded>(data),
      error: null,
    };
  } catch (e) {
    return { data: null, error: { message: 'We were unable to process your request' } };
  }
};

export const getScheduleWeeks = async (): PromiseResponse<NormalizedScheduledOrders> => {
  try {
    const { data } = await axios.get<ScheduledOrdersServerResponse>('schedule_weeks');
    return normalize<NormalizedScheduledOrders>(data);
  } catch (e) {
    throw new Error('We were unable to process your request');
  }
};

interface editScheduledOrderParams {
  scheduleWeekId: string;
  items: { productVariantId: string; quantity: number }[];
}

export const editScheduledOrder = async (
  params: editScheduledOrderParams
): PromiseResponse<SingleOrderWithFullInfoServerResponse> => {
  try {
    const { data } = await axios.post<ObjectToCamel<SingleOrderWithFullInfoServerResponse>>(
      'orders',
      decamelizeKeys(params)
    );
    return data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    if (e.response.status === 400 && e.response.data.error.includes('voucher')) {
      throw new Error(e.response.data.message);
    }
    throw new Error('We were unable to process your request');
  }
};

interface updateUpcomingOrderParams {
  orderId: string;
  params: editScheduledOrderParams;
}

export const updateUpcomingOrder = async ({
  orderId,
  params,
}: updateUpcomingOrderParams): PromiseResponse<SingleOrderServerResponse> => {
  try {
    const { data } = await axios.put<ObjectToCamel<SingleOrderServerResponse>>(
      `orders/${orderId}`,
      decamelizeKeys(params)
    );
    return data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    if (e.response.status === 400 && e.response.data.error.includes('voucher')) {
      throw new Error(e.response.data.message);
    }
    throw new Error('We were unable to process your request');
  }
};

interface changeUpcomingOrderDeliveryDateParams {
  orderId: string;
  params: { deliveryDate: string };
}

export const changeUpcomingOrderDeliveryDate = async ({
  orderId,
  params,
}: changeUpcomingOrderDeliveryDateParams): PromiseResponse<Order> => {
  try {
    const { data } = await axios.post<ObjectToCamel<SingleOrderServerResponse>>(
      `orders/${orderId}/delivery_date`,
      decamelizeKeys(params)
    );
    return data.data;
  } catch (e) {
    throw new Error('We were unable to process your request');
  }
};

interface changeUpcomingOrderDeliveryAddressParams {
  orderId: string;
  addressId: string;
}

export const changeUpcomingOrderDeliveryAddress = async ({
  orderId,
  addressId,
}: changeUpcomingOrderDeliveryAddressParams): PromiseResponse<SingleOrderWithAddressServerResponse> => {
  try {
    const { data } = await axios.put<ObjectToCamel<SingleOrderWithAddressServerResponse>>(
      `orders/${orderId}/shipping_address/${addressId}`
    );
    return data;
  } catch (e) {
    throw new Error('We were unable to process your request');
  }
};

const pickForMeAndCreateOrder = async (
  scheduleWeekId: string
): PromiseResponse<NormalizedOrderWithAddressIncluded> => {
  try {
    const result = await axios.post<Order>(`schedule_weeks/${scheduleWeekId}/chef_selections`);
    return normalize<NormalizedOrderWithAddressIncluded>(result.data);
  } catch (e) {
    throw new Error('We were unable to process your request');
  }
};

const pickForMeAndUseOrderId = async (
  orderId: string
): PromiseResponse<NormalizedOrderWithAddressIncluded> => {
  try {
    const result = await axios.post<Order>(`orders/${orderId}/chef_selections`);
    return normalize<NormalizedOrderWithAddressIncluded>(result.data);
  } catch (e) {
    throw new Error('We were unable to process your request');
  }
};

interface PickForMeParams {
  scheduleWeekId: string;
  orderId?: string | null;
}
export const pickForMe = async ({
  scheduleWeekId,
  orderId = null,
}: PickForMeParams): PromiseResponse<NormalizedOrderWithAddressIncluded> => {
  if (orderId) {
    return pickForMeAndUseOrderId(orderId);
  }
  return pickForMeAndCreateOrder(scheduleWeekId);
};

export const skipScheduledWeek = async (
  scheduleWeekId: string
): PromiseResponse<ScheduledOrder> => {
  try {
    const { data } = await axios.post<{ data: ScheduledOrder }>(
      `schedule_weeks/${scheduleWeekId}/skip`
    );
    return data.data;
  } catch (e) {
    throw new Error('We were unable to process your request');
  }
};

export interface RatingParams {
  lineItemId: string;
  rating: number;
  notes: string;
  reason?: string;
}

export const rateProduct = async (params: RatingParams): PromiseResponse<ProductRating> => {
  try {
    const { data } = await axios.post<ObjectToCamel<RatingResponse>>(
      `product_ratings`,
      decamelizeKeys(params)
    );
    return data.data;
  } catch (e) {
    throw new Error('We were unable to process your request');
  }
};

export const getOrderById = async (
  orderId: string
): PromiseResponse<SingleOrderWithFullInfoServerResponse> => {
  try {
    const { data } = await axios.get<ObjectToCamel<SingleOrderWithFullInfoServerResponse>>(
      `orders/${orderId}`
    );
    return data;
  } catch (e) {
    throw new Error('We were unable to process your request');
  }
};

export const unskipScheduledWeek = async (
  scheduleWeekId: string
): PromiseResponse<ScheduledOrder> => {
  try {
    const { data } = await axios.post<{ data: ScheduledOrder }>(
      `schedule_weeks/${scheduleWeekId}/unskip`
    );
    return data.data;
  } catch (e) {
    throw new Error('We were unable to process your request');
  }
};

export interface ConfirmOrderParams {
  paymentId: string;
}

export const confirmOrder = async (params: ConfirmOrderParams) => {
  try {
    await axios.post(`orders/confirm`, decamelizeKeys(params));
    return { data: true, error: null };
  } catch (e) {
    Sentry.withScope(scope => {
      scope.setExtra('payload', params);
      scope.setExtra('Axios error', e);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      scope.setExtra('Server Response', (e as any)?.response?.data);
      Sentry.captureException(new Error('Order confirmation failed'));
    });
    return { data: null, error: { message: 'We were unable to process your request' } };
  }
};

export interface OrderFeedbackParams {
  notes?: string | null;
  reason?: string | null;
  npsRating?: number | null;
  hearAboutUs?: string | null;
  hearAboutUsNotes?: string | null;
  attachmentS3Key?: string | null;
}

interface giveFeedbackParams {
  orderId: string;
  params: OrderFeedbackParams;
}

export const giveFeedback = async ({
  orderId,
  params,
}: giveFeedbackParams): PromiseResponse<OrderFeedbackServerResponse['data']> => {
  try {
    const { data } = await axios.post<ObjectToCamel<OrderFeedbackServerResponse>>(
      `orders/${orderId}/feedback`,
      decamelizeKeys(params)
    );
    return data.data;
  } catch (e) {
    throw new Error('We were unable to process your request');
  }
};

interface applyVoucherToUpcomingOrderParams {
  orderId: string;
  code: string;
}

export const applyVoucher = async ({
  orderId,
  code,
}: applyVoucherToUpcomingOrderParams): PromiseResponse<NormalizedOrders> => {
  try {
    const { data } = await axios.post<ObjectToCamel<SingleOrderServerResponse>>(
      `orders/${orderId}/voucher`,
      {
        code: code.toUpperCase(),
      }
    );
    return normalize<NormalizedOrders>(data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    const message = e.response?.status === 400 ? e.response.data.message : 'Invalid code';
    throw new Error(message);
  }
};

export const removeVoucher = async (orderId: string): PromiseResponse<NormalizedOrders> => {
  try {
    const { data } = await axios.post<ObjectToCamel<SingleOrderServerResponse>>(
      `orders/${orderId}/remove_voucher`
    );
    return normalize<NormalizedOrders>(data);
  } catch (e) {
    throw new Error('Something went wrong, we were unable to remove your voucher.');
  }
};

interface changeDeliveryDateForScheduleWeekParams {
  scheduleWeekId: string;
  deliveryDate: string;
}
export const changeDeliveryDateForScheduleWeek = async ({
  scheduleWeekId,
  deliveryDate,
}: changeDeliveryDateForScheduleWeekParams): PromiseResponse<ScheduledOrder> => {
  try {
    const { data } = await axios.put<ObjectToCamel<{ data: ScheduledOrder }>>(
      `schedule_weeks/${scheduleWeekId}`,
      {
        delivery_date: deliveryDate,
      }
    );
    return data.data;
  } catch (e) {
    throw new Error('We were unable to process your request');
  }
};

export const applyGiftOrCreditVoucher = async (code: string): PromiseResponse<CustomerData> => {
  try {
    const { data } = await axios.post<ObjectToCamel<{ data: CustomerData }>>(
      'customers/credit_voucher',
      {
        code: code.toUpperCase(),
      }
    );
    return data.data;
  } catch (e) {
    throw new Error('Unable to apply voucher');
  }
};

interface CreatePaymentForOrderParams {
  orderId: string;
  paymentMethodId?: string;
  paymentMethodType?: 'CreditCard';
  paymentProcessorName?: 'Stripe';
}

interface ServerCreatePaymentResponse {
  payment_intent_client_secret: string | null;
  payment: PaymentServerResponse;
}

export const createPaymentForOrder = async ({
  orderId,
  paymentMethodId,
  paymentMethodType = 'CreditCard',
  paymentProcessorName = 'Stripe',
}: CreatePaymentForOrderParams): ApiResponse<ServerCreatePaymentResponse> => {
  const params = paymentMethodId
    ? { paymentMethodId, orderId }
    : { orderId, paymentMethodType, paymentProcessorName };

  try {
    const result = await axios.post('payments', decamelizeKeys(params));
    return { data: result.data, error: null };
  } catch (e) {
    Sentry.withScope(scope => {
      scope.setExtra('payload', { orderId, paymentMethodId });
      scope.setExtra('Axios error', e);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      scope.setExtra('Server Response', (e as any)?.response?.data);
      Sentry.captureException(new Error('Payment creation failed'));
    });
    return { data: null, error: { message: 'We were unable to process your request' } };
  }
};

type PreviewOrderParams = editScheduledOrderParams & {
  voucherCode?: string;
};

export const previewOrder = async (params: PreviewOrderParams) => {
  try {
    const result = await axios.post<ObjectToCamel<SingleOrderWithFullInfoServerResponse>>(
      'preview_order',
      decamelizeKeys(params)
    );
    return result.data;
  } catch (e) {
    if (isAxiosError(e)) {
      Sentry.withScope(scope => {
        if (isAxiosError(e)) {
          scope.setExtra('Server Response', e?.response?.data);
        }
        Sentry.captureException(new Error('Preview order failed'));
      });
      throw new Error('Preview order failed');
    }
  }
};

export const sendInvoice = async (orderId: string) => {
  try {
    await axios.post(`orders/${orderId}/send_invoice`);
  } catch (e) {
    throw new Error('Failed to send invoice');
  }
};
