<template>
  <nav class="w-full border-b border-solid border-sienna-muted">
    <NavBarProductBox
      v-if="isOnProductBox"
      :is-logged-in="isLoggedIn"
      @open-mobile="isMobileMenuOpen = true"
      @open-modal="$emit('openModal')"
      @logout="logout"
    />
    <NavBarCheckout v-else-if="isOnCheckout" />
    <NavBarPastaNow
      v-else-if="isOnPastaNow"
      :is-logged-in="isLoggedIn"
      :hide-toggle="!shouldShowPastaNowToggle"
      @open-mobile="isMobileMenuOpen = true"
      @logout="logout"
    />
    <NavBarMyAccount
      v-else
      :is-logged-in="isLoggedIn"
      :is-on-login-or-signup="isOnLoginOrSignUp"
      :active-icon="Boolean(isOnMyAccount)"
      @open-mobile="isMobileMenuOpen = true"
      @logout="logout"
    />
  </nav>
  <NavBarMobile
    v-model="isMobileMenuOpen"
    :is-logged-in="isLoggedIn"
    :show-my-account-links="!isOnProductBox && !isOnCheckout"
    @open-modal="openModal"
    @logout="logout"
  />
</template>

<script lang="ts" setup>
  import { computed, nextTick, ref } from 'vue';
  import { useRouter } from 'vue-router';
  import useCurrentCustomer from '@/stores/currentCustomerStore';
  import usePreventScroll from '@/composables/usePreventScroll';
  import * as Sentry from '@sentry/vue';
  import useLogout from '@/api/mutations/pensa/logout';

  const emit = defineEmits<{ (e: 'openModal'): void }>();

  const customerStore = useCurrentCustomer();
  const router = useRouter();
  const isMobileMenuOpen = ref(false);
  const logoutMutation = useLogout();

  usePreventScroll(isMobileMenuOpen);

  const isOnCheckout = computed(
    () =>
      router.currentRoute.value.name &&
      typeof router.currentRoute.value.name === 'string' &&
      [
        'checkout-login',
        'checkout-signup',
        'checkout-billing-address',
        'checkout-payment',
        'delivery-address',
        'delivery-instructions',
        'checkout-social-login',
        'pasta-now-checkout-login',
        'pasta-now-checkout-social-login',
        'pasta-now-checkout-signup',
        'pasta-now-delivery-address',
        'pasta-now-delivery-instructions',
        'pasta-now-payment',
      ].includes(router.currentRoute.value.name)
  );

  const isOnProductBox = computed(() => router.currentRoute.value.name === 'product-box');

  const isOnPastaNow = computed(
    () =>
      router.currentRoute.value.name &&
      (router.currentRoute.value.name as string).startsWith('pasta-now')
  );

  const shouldShowPastaNowToggle = computed(
    () => router.currentRoute.value.name !== 'pasta-now-search-address'
  );

  const isOnMyAccount = computed(
    () =>
      router.currentRoute.value.name &&
      typeof router.currentRoute.value.name === 'string' &&
      [
        'upcoming-orders',
        'my-account-dashboard',
        'order-history',
        'rewards',
        'details',
        'pasta-plan',
      ].includes(router.currentRoute.value.name)
  );

  const isLoggedIn = computed(() => Boolean(customerStore.email));

  const isOnLoginOrSignUp = computed(() =>
    Boolean(
      router.currentRoute.value.name &&
        typeof router.currentRoute.value.name === 'string' &&
        ['login', 'signup', 'social-login'].includes(router.currentRoute.value.name)
    )
  );

  watch(
    () => customerStore.email,
    () => {
      if (customerStore.email) {
        Sentry.setUser({ email: customerStore.email });
      }
    },
    { immediate: true }
  );

  const logout = () => {
    logoutMutation.mutate(undefined, {
      onSuccess: () => {
        isMobileMenuOpen.value = false;
        if (import.meta.env.VITE_IS_PROD) {
          window.location.replace('https://pastaevangelists.com/account/logout');
        }
      },
    });
  };
  const openModal = () => {
    isMobileMenuOpen.value = false;
    nextTick(() => emit('openModal'));
  };
</script>
